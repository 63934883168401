import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
    <footer className="page-footer">
        <img src="/img/footer-logo.svg" className="logo"/>
        <ul className="page-footer__list">
        <li className="page-footer__list-item">
            <a target="_blank" href="/privacy" className="page-footer__list-link">&copy; 2018
            UTOPIE. All Rights Reserved. Privacy Policy.</a>
            </li>
        <li className="page-footer__list-item">
            <a 
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/utopie.maroc/"
            className="page-footer__list-link">
            Facebook.
            </a>
        </li>
        <li className="page-footer__list-item">
            <a 
            rel="noopener noreferrer"
            target="_blank"
            href="https://twitter.com/Utopie_Maroc"
            className="page-footer__list-link">
            Twitter.
            </a>
        </li>
        <li className="page-footer__list-item">
            <a 
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/utopiemaroc/"
            className="page-footer__list-link">
            LinkedIN.
            </a>
        </li>
        <li className="page-footer__list-item">
            <a 
            href="tel:+212522784478"
            className="page-footer__list-link"
            >
            +212 (0) 522 78 44 78
            </a>
        </li>
        <li className="page-footer__list-item">
            <a 
            href="mailto:contact@utopie.ma"
            className="page-footer__list-link"
            >
            contact@utopie.com
            </a>
        </li>
        </ul>
    </footer>
)

export default Footer
