// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const MethodologySection = ({ title, content, steps }) => (
	<section className="manifest__section manifest__section--expanded manifest__section--methodology">
		<div className="manifest__section-container">
			<h1 className="manifest__heading">{ title }</h1>
			<p className="manifest__tagline">
				{ content }
			</p>
			<p className="manifest__services-summary">
                {
                    steps.map((step, index) => (
                        <span key={index} className="manifest__services-summary-item">
                            {step}
                        </span>
                    ))
                }
			</p>
		</div>
	</section>
)

MethodologySection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  steps: PropTypes.array,
}

MethodologySection.defaultProps = {
  title: ``,
  content: ``,
  steps: [],
}

export default MethodologySection
