// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const StepsBlock = ({ title, steps }) => (
	<div data-rellax-speed="-2.25" className="manifest__section-side manifest__section-side--small manifest__section-side--highlight">
        <h1 className="manifest__heading">Nos Valeurs.</h1>
        <ul className="manifest__value-list">
            {
                steps.map((step, index) => (
                    <li key={index} className="manifest__value-list-item">
                      {step.title}: &nbsp;
                      <small style={{ marginBottom: "5px", fontSize: "57%", display: "block" }} >{step.text}</small>
                    </li>
                ))
            }
        </ul>
    </div>
)

StepsBlock.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
}

StepsBlock.defaultProps = {
  title: ``,
  steps: [],
}

export default StepsBlock
