// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import StepsBlock from "./steps-block"
import MethodologySection from "./methodology-section"
import NewsletterForm from "./newsletter-form"
import Footer from "./footer"

const WhoAreWe = () => (
    <div>
        <section className="manifest__section manifest__section--expanded manifest__section--dark manifest__section--values">
            <div className="manifest__section-container">
                <StepsBlock 
                    title="Nos Valeurs." 
                    steps={[ 
                        {
                            title: "Innovation",
                            text: "De la même manière que la demande du marché ne cesse d’évoluer, nous réinventons notre savoir-faire pour nous maintenir à la pointe de la technologie. "
                        }, {
                            title: "Passion",
                            text: "Utopie est née d’une passion, celle de ce que la technologie apporte aux Hommes. "
                        }, {
                            title: "Gratitude et humilité",
                            text: "Garder les pieds au sol nous permet de conserver une vision plus juste des choses qui nous entourent et de ce que nous sommes. "
                        }
                    ]} 
                />
                <div className="manifest__section-side manifest__section-side--big">
                    <h1 className="manifest__heading">Qui sommes-nous ?</h1>
                    <p className="manifest__para manifest__para--invert">
                        Depuis 2014, Utopie accompagne avec succès les entreprises dans leur transformation numérique et se charge de la création, la maintenance et la gestion de leurs portefeuilles d’applications.
                        <br />
                        Nous mettons la rencontre de passionnés du métier au service d’entreprises de tailles et d’horizons différents.
                        <br />
                        Forts de notre maîtrise du marché local combinée à l’expérience de nos partenaires internationaux, nous vous proposons des dispositifs aux dimensions de vos attentes.
                        <br />
                        <b> Nous faisons de votre idéal notre standard. </b>
                    </p>
                </div>
            </div>
        </section>

        <MethodologySection title="Notre engagement" content={` 
            À Utopie, nous n’empruntons pas un chemin uniquement pour sa facilité. Nous faisons de la complexité un moteur de créativité. L'efficacité et l'efficience sont des aspects clés de notre approche. Nous ne reculons devant aucune de vos demandes. Quelle que soit la tâche à accomplir, nous nous y attelons.
        `} steps={ [ ] } />

        <NewsletterForm />

        <Footer />
    </div>
)

export default WhoAreWe
