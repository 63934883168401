// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Sponsores = () => (
    <div>
        <blockquote className="quote quote--small">
            
            <h2 className="partners-header manifest__heading">ILS NOUS FONT CONFIANCE</h2>

            <ul className="manifest__certification-list">
                
                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/10.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/11.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/12.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/1.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/2.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/3.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/4.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/5.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/6.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/7.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/8.jpg")' }} 
                />

                <li 
                    title="Google Search Advertising"
                    className="manifest__certification-list-item manifest__certification-list-item--google-search-advertising"
                    style={{ backgroundImage: 'url("/img/partners/9.jpg")' }} 
                />

            </ul>
            
        </blockquote>
    </div>
)

export default Sponsores
