import React from "react"
import { Form, Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { submitNewsLetter, postNewsLetterCompleted } from '../store/actions'
import ReCAPTCHA from "react-google-recaptcha";
let recaptchaInstance;

class NewsletterForm extends React.Component {

  render = () => (
    <form action="#" method="post" className="newsletter-form">
        <fieldset className="newsletter-form__fieldset">
            <legend className="newsletter-form__legend">Restez informés</legend>
            <Field
              name="email"
              type="email"
              className="newsletter-form__input inuit-u-p-"
              placeholder="Addresse Email"
              component="input"
              required
            />
            <ReCAPTCHA
              render="explicit"
              ref={e => recaptchaInstance = e}
              sitekey="6LcLkaEUAAAAAJEdZJ2Wl_JScVQ6o4cH0N9jk-Fs"
              size="invisible"
              onChange={this.verifyCallback}
            />
            <button
              onClick={ e => this.executeCaptcha() }
              type="button"
              className="newsletter-form__submit inuit-btn"
            >
              {
                this.props.completed ? "S’inscrire" : "Chargement ..."
              }
            </button>
        </fieldset>
    </form>
  )

  executeCaptcha = () => {
    this.props.showLoader();
    recaptchaInstance.execute();
  }


  verifyCallback = (response) => {
    let dataPosted = { recaptcha: response }
    this.props.postForm({
      additionalData: dataPosted,
      callBack: () => {}
    })
  }

  componentDidMount = () => {
    // helpers.initPagePilling()
  }

}

const mapStateToProps = (state) => {
  return {
    completed: state.contactReducer.newsletterCompleted,
    error: state.contactReducer.newsletterError,
    success: state.contactReducer.newsletterSuccess
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      showLoader: () => dispatch(postNewsLetterCompleted(false)),
      postForm: (data) => dispatch(submitNewsLetter(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'news-letter' // a unique identifier for this form
})(NewsletterForm))
